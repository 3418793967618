import Ably from 'ably';

const ably = new Ably.Realtime({ key: 'I3odDA.h0A9LQ:5m40f5wIWUKctSYLDjug7MQnz_fBKsXsWv5aG_LdtaA' });

function getAblyChannel(channelName) {
  return ably.channels.get(channelName);
}

async function publishMessage(ablyChannel, message) {
  return new Promise((resolve, reject) => {
    ablyChannel.publish('message', message, (err) => {
      if (err) {
        console.error('Error publishing message:', err);
        reject(err);
      } else {
        //console.log('Message published:', message);
        resolve();
      }
    });
  });
}

function subscribeToMessages(ablyChannel, callback) {
  ablyChannel.subscribe('message', (msg) => {
    //console.log('Message received:', msg);
    callback(msg);
  });
}

function unsubscribeFromMessages(ablyChannel) {
  ablyChannel.unsubscribe();
}

export { getAblyChannel, publishMessage, subscribeToMessages, unsubscribeFromMessages };
